<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left booking">
          <div class="kw-back">
            <h3 class="mb-0">{{ $t("BOOKINGS.BOOKING_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="../../../../../public/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <router-link
            v-if="$currentUserCan($permissions.PERM_CREATE_BOOKINGS)"
            :to="{ name: 'New Booking' }"
            v-slot="{ href, navigate }"
            custom
          >
            <base-button
              :href="href"
              @click="navigate"
              class="elite-button add"
              icon
              size="sm"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </span>
            </base-button>
          </router-link>
        </div>
      </div>

      <booking-list-table
        @onViewBooking="openBookingViewModal"
        @onEditBooking="openBookingEditModal"
        @onDeleteBooking="deleteBooking"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'BOOKING_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("BOOKINGS.VIEW_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openBooking"
                :objectType="'bookings'"
                :objectId="openBooking.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openBookingEditModal(openBooking)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteBooking(openBooking)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-booking-component
              v-if="openBooking"
              :bookingId="openBooking.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("BOOKINGS.EDIT_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openBookingViewModal(openBooking)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-booking-component
              v-if="openBooking"
              :bookingId="openBooking.id"
              @onViewBooking="openBookingViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <!--            <add-booking-component @onViewBooking="openBookingViewModal" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import BookingListTable from "./partials/BookingListTable.vue";
import EditBookingComponent from "./components/EditBookingComponent.vue";
import AddBookingComponent from "./components/AddBookingComponent.vue";
import ViewBookingComponent from "./components/ViewBookingComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BookingListTable,
    NotificationSubscription,
    EditBookingComponent,
    //AddBookingComponent,
    ViewBookingComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const bookingId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewBookingModalOpened = false;
    let isEditBookingModalOpened = false;
    let isAddBookingModalOpened = false;
    let openBooking = null;
    if (bookingId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewBookingModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditBookingModalOpened = true;
      }
      openBooking = { id: bookingId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddBookingModalOpened = true;
    }
    return {
      isViewBookingModalOpened: isViewBookingModalOpened,
      isEditBookingModalOpened: isEditBookingModalOpened,
      isAddBookingModalOpened: isAddBookingModalOpened,
      openBooking: openBooking,
      renderKey: 1,
    };
  },

  methods: {
    openBookingCreateModal() {
      this.closeBookingModal();
      this.isAddBookingModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openBookingViewModal(booking, reRender = false) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.isViewBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openBookingEditModal(booking) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.isEditBookingModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeBookingModal() {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = false;
      this.isEditBookingModalOpened = false;
      this.openBooking = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: {},
        }).href
      );
    },

    async deleteBooking(booking) {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("bookings/destroy", booking.id);
          this.renderKey++;
          this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
