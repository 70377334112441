<template>
  <div class="elite-tabs-wrapper-content">
    <h3 v-if="booking.company_name">{{ `${booking.company_name}` }}</h3>
    <h3 v-else>{{ `${booking.firstname} ${booking.lastname}` }}</h3>
    <div class="col-12">
      <dl class="row" v-if="booking?.bookingable?.type">
        <dt class="col-sm-4">
          {{ bookingableTypeName }}
        </dt>
        <dd class="col-sm-8">
          <router-link
            v-if="booking.organization"
            :to="$objectViewRoute(booking.bookingable)"
          >
            {{ `${bookingableName}` }}
          </router-link>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TITLE") }}</dt>
        <dd class="col-sm-8" v-if="booking?.title">
          {{ $t("COMMON." + booking?.title) }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.firstname">
        <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
        <dd class="col-sm-8 text-capitalize">
          {{ booking.firstname }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.lastname">
        <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ booking.lastname ?? "" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ booking.company_name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
        <dd class="col-sm-8">
          {{ booking.email }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.phone">
        <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
        <dd class="col-sm-8">
          <phone-number
            :phoneNumber="booking.phone"
            :extension="booking.phone_extension"
            :type="booking.phone_type"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.BIRTHDAY") }}</dt>
        <dd class="col-sm-8" v-if="booking.birthday">
          {{ $formatDate(booking.birthday, "dddd D MMMM YYYY") }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.country">
        <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
        <dd class="col-sm-8">
          {{ booking.country }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.state">
        <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ booking.state }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.city">
        <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ booking.city }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.zipcode">
        <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
        <dd class="col-sm-8">
          {{ booking.zipcode }}
        </dd>
      </dl>

      <dl class="row" v-if="booking.address">
        <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ booking.address }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="booking.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="booking.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ booking.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ booking.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "booking-view-global",

  props: ["booking"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {
    bookingableName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.booking.bookingable.company_name;
      } else if (this.booking.bookingable.type == "customers") {
        return this.booking.bookingable.customer_name;
      }
      return "N/A";
    },
    bookingableTypeName() {
      if (this.booking.bookingable.type == "bookings") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.booking.bookingable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    bookingUpdated() {
      this.$emit("bookingUpdated", true);
    },
  },

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>
